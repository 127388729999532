import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PageHeader, Button, Modal } from "antd";
import { DownOutlined, MenuOutlined, LoginOutlined } from "@ant-design/icons";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";

import "./style.scss";
import { IPayloadLoginGoogle } from "@globalTypes/globalTypes";
import {
  saveAccessToken,
  getStringLocalStorage,
  readAccessToken,
} from "@utils/localStorage";
import { getUserReducer, logOutReducer } from "@apps/redux/actions/auth";
import { RootState } from "@apps/redux/reducers";
import { Menu } from "@components/menu";
import authApi from "@apps/api/auth";
import { notifyError, notifySuccess } from "@utils/helperFuntions";
import i18n from "@locales/i18n";
import { MENU_ITEMS } from "@utils/constants";

const menus = [
  {
    label: "home",
    key: MENU_ITEMS.HOME,
    url: "/",
    isProtected: false,
  },
  {
    label: "posts.title",
    key: MENU_ITEMS.NEWS,
    url: "/news",
    isProtected: false,
  },
  {
    label: "organization",
    key: MENU_ITEMS.ORGANIZATION,
    url: "/organization",
    isProtected: true,
  },
  {
    label: "tools",
    key: MENU_ITEMS.TOOLS,
    url: "/tool",
    isProtected: true,
  },
  {
    label: "study",
    key: MENU_ITEMS.STUDY,
    url: "/study",
    isProtected: false,
  },
  {
    label: "chatSupport",
    key: MENU_ITEMS.CHAT_SUPPORT,
    url: "/chat-support",
    isProtected: true,
  },
];

const logo = "/images/logo_du10.svg";

interface ILogoLanguage {
  id: number;
  language: string;
  image: string;
  isActive: boolean;
  value: string;
}

export default function Header() {
  const { t } = useTranslation();

  const home = useSelector((state: any) => state.home);

  const [showMenuMobile, setShowMenuMobile] = useState(false);
  const [showLanguage, setShowLanguage] = useState(false);
  const [userDropdown, setUserDropdown] = useState(false);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [isLoginFail, setIsLoginFail] = useState(false);
  const [languageLogo, setLanguageLogo] = useState<ILogoLanguage[]>([
    {
      id: 1,
      language: "日本語",
      image: "/images/iconJP.svg",
      isActive: false,
      value: "jp",
    },
    {
      id: 2,
      language: "English (US)",
      image: "/images/iconUS.svg",
      isActive: false,
      value: "en",
    },
    {
      id: 3,
      language: "Việt Nam",
      image: "/images/icon-vn.svg",
      isActive: true,
      value: "vi",
    },
  ]);

  const { user, accessToken, success } = useSelector(
    (state: RootState) => state.auth,
  );

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    getCurrentLanguage();
  }, []);

  useEffect(() => {
    const languageItem = languageLogo.find((item) => item.isActive);
    i18n.changeLanguage(languageItem?.value);
  }, [languageLogo]);

  useEffect(() => {
    if (isLoginSuccess) {
      window.location.reload();
      notifySuccess(t("loginSuccess"));
    }
    if (isLoginFail) notifyError(t("loginFailed"));
  }, [isLoginSuccess, isLoginFail, t]);

  const checkLogged = () => {
    clickMenu();
    notifyError(t("youAreNotLogin"));
  };

  const getCurrentLanguage = () => {
    const currentLanguage: string = getStringLocalStorage("i18nextLng") || "";
    if (currentLanguage) {
      const result: ILogoLanguage[] = languageLogo.map((item) => {
        const isActive: boolean = item.value === currentLanguage;
        return {
          ...item,
          isActive,
        };
      });
      setLanguageLogo(result);
    }
  };

  const showModal = () => {
    const appLayout = document.getElementById("app-layout");
    if (appLayout) {
      appLayout.style.filter = "blur(6px)";
    }
    setShowMenuMobile(true);
  };

  const handleClose = () => {
    const appLayout = document.getElementById("app-layout");
    if (appLayout) {
      appLayout.style.filter = "none";
    }
    setShowMenuMobile(false);
    setShowLanguage(false);
  };

  const clickMenu = () => {
    handleClose();
  };

  const chooseLanguage = (itemSelected: ILogoLanguage) => {
    const result: ILogoLanguage[] = languageLogo.map((item) => {
      const isActive: boolean = item.id === itemSelected.id;
      return {
        ...item,
        isActive,
      };
    });
    setLanguageLogo(result);
    handleClose();
  };

  const getIconLanguageSelected = () => {
    return languageLogo.find((item) => item.isActive);
  };

  const onHandleLoginGoogle = useGoogleLogin({
    onSuccess: async (codeResponse: any) => {
      if (codeResponse.access_token) {
        const payload: IPayloadLoginGoogle = {
          access_token: codeResponse.access_token,
        };
        const response = await authApi.loginGoogle(payload);
        const dataRes = response?.data?.data || {};
        if (dataRes.access_token) {
          saveAccessToken(dataRes.access_token);
          dispatch(await getUserReducer(dataRes.access_token));
          setIsLoginSuccess(true);
        }
      }
      handleClose();
    },
    onError: () => {
      setIsLoginFail(true);
    },
    flow: "implicit",
  });

  const onHandleLogout = async () => {
    setUserDropdown(false);
    dispatch(await logOutReducer(accessToken));
    setIsLoginSuccess(false);
    if (success) {
      window.location.reload();
      notifySuccess(t("logoutSuccess"));
    } else notifyError(t("logoutFailed"));
  };

  const closeUserDropDown = () => {
    setUserDropdown(false);
    navigate("/news-watch-later");
  };

  return (
    <PageHeader
      className={`top-0 page-header w-full z-50 bg-[#FEF8FB] ${
        location.pathname === "/tool"
          ? "fixed"
          : location.pathname === "/"
          ? "absolute"
          : ""
      }
      ${
        location.pathname === "/" && home.homeSetting.darkTheme
          ? " dark-theme"
          : ""
      }
      `}
    >
      <div className="md:container flex items-center justify-between">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="logo" width={136} height={48} />
          </Link>
        </div>
        <div className="w-full hidden items-center md:flex">
          {/* Menu  */}
          <div
            style={{ width: "inherit" }}
            className="md:px-[3%] lg:pl-[8%] lg:pr-[5%]"
          >
            <Menu className="menu-header" items={menus} />
          </div>

          <div className="hidden md:flex items-center">
            {/* language for screen desktop  */}
            <div
              className="flex items-center md: mr-4 lg:mr-10 w-[140px]"
              onMouseLeave={() => setShowLanguage(false)}
            >
              <div className="relative cursor-pointer p-4">
                <div
                  className="flex items-center"
                  onMouseOver={() => setShowLanguage(true)}
                >
                  <img
                    key={getIconLanguageSelected()?.id}
                    src={getIconLanguageSelected()?.image}
                    alt="icon-language"
                    width={24}
                    height={24}
                    className="rounded-full object-cover object-center min-w-[24px] min-h-[24px]"
                  />
                  <DownOutlined className="ml-2 text-[12px]" />
                </div>
                {showLanguage && (
                  <div className="absolute bg-white p-4 rounded-lg whitespace-nowrap mt-3 w-[140px]">
                    {languageLogo.map((item, index) => {
                      return (
                        <div
                          className={`flex gap-2 ${
                            index !== languageLogo.length - 1 ? "pb-2" : ""
                          } `}
                          key={index}
                          onClick={() => chooseLanguage(item)}
                        >
                          <img
                            src={item.image}
                            alt="icon-language"
                            width={24}
                            height={24}
                          />
                          <p className="text-[#000] font-bold hover:text-[#db2f69]">
                            {item.language}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>

            {/* icon login  */}

            {user && user.email ? (
              <OutsideClickHandler
                onOutsideClick={() => setUserDropdown(false)}
              >
                <div className="relative">
                  <img
                    className="w-10 h-10 max-w-none rounded-full cursor-pointer"
                    src={user.avatar}
                    alt="User dropdown"
                    onClick={() => {
                      setUserDropdown(!userDropdown);
                    }}
                  />

                  {userDropdown && (
                    <div className="absolute top-full left-[-100px] 2xl:left-0 mt-2">
                      <div className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                        <div className="px-4 py-3 text-sm text-gray-900 dark:text-white">
                          <div>{user.name}</div>
                          <div className="font-medium truncate">
                            {user.email}
                          </div>
                        </div>
                        <div className="py-1">
                          <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer">
                            <span onClick={closeUserDropDown}>
                              Các bài viết xem sau
                            </span>
                          </div>
                        </div>
                        <div className="py-1">
                          <div
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer"
                            onClick={onHandleLogout}
                          >
                            {t("btnLogout")}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </OutsideClickHandler>
            ) : (
              <LoginOutlined
                onClick={() => onHandleLoginGoogle()}
                className="w-10 text-[20px] cursor-pointer"
              />
            )}
          </div>
        </div>
        {user && user.email && <p className="md:hidden">{user.email}</p>}
        <div>
          <Button
            type="text"
            shape="circle"
            icon={<MenuOutlined />}
            className="inline-block md:hidden !text-[#db2f69] !text-[20px]"
            onClick={showModal}
          ></Button>
          <Modal
            open={showMenuMobile}
            footer={null}
            onCancel={handleClose}
            style={{ top: 0, margin: 0, padding: 0, maxWidth: "100%" }}
            wrapClassName="modal-menu-header"
            maskStyle={{ background: "unset" }}
            bodyStyle={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
            }}
            width={"100%"}
          >
            <div className="absolute flex flex-col top-[10%] w-[83%]">
              {menus.map((item: any, index: any) => {
                return (
                  <div key={index}>
                    <div className="flex items-center justify-between">
                      {(!item.isProtected && !readAccessToken()) ||
                      readAccessToken() ? (
                        <Link
                          className={`${
                            item.isActive && "!text-[#db2f69]"
                          } text-[#5a5657] font-bold text-2xl py-1 capitalize`}
                          key={index}
                          to={item.url}
                          onClick={() => clickMenu()}
                        >
                          {t(item.label)}
                        </Link>
                      ) : (
                        <div
                          className={`text-[#5a5657] font-bold text-2xl py-1 capitalize cursor-pointer ${
                            item.isActive && "!text-[#db2f69]"
                          }`}
                          onClick={() => checkLogged()}
                        >
                          {t(item.label)}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
              <div className="flex items-center justify-between">
                <div
                  onClick={() => setShowLanguage(!showLanguage)}
                  className="text-[#5a5657] font-bold text-2xl py-1"
                >
                  {t("language")}
                </div>
                <DownOutlined
                  onClick={() => setShowLanguage(!showLanguage)}
                  className={`${
                    !showLanguage && "icon-show-menu"
                  } ml-2 text-[14px]`}
                />
              </div>
              {showLanguage &&
                languageLogo?.map((item: any, index: any) => {
                  return (
                    <div className="flex pr-5 pb-3 last:pb-0 ml-6" key={index}>
                      <img className="w-[24px]" src={item.image} alt="" />
                      <p
                        className={` ${
                          item.isActive && "!text-[#db2f69]"
                        } pl-4 text-[#7c7979] font-bold py-2`}
                        key={index}
                        onClick={() => chooseLanguage(item)}
                      >
                        {item.language}
                      </p>
                    </div>
                  );
                })}
              <div
                className="flex items-center mt-6 cursor-pointer justify-center py-2 rounded-xl bg-[#DB2F69] active:bg-[#f184a9] text-white"
                onClick={() => {
                  if (readAccessToken()) {
                    onHandleLogout();
                  } else {
                    onHandleLoginGoogle();
                  }
                }}
              >
                <div className="font-bold text-base text-center">
                  {t(`${readAccessToken() ? "btnLogout" : "sign_in"}`)}
                </div>
                <LoginOutlined className="text-[20px] ml-4" />
              </div>
            </div>
          </Modal>
          <ToastContainer />
        </div>
      </div>
    </PageHeader>
  );
}
