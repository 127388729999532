import { useEffect } from "react";
import { Layout } from "antd";
import { useDispatch } from "react-redux";

import Header from "./header";
import Footer from "./footer";
import { readAccessToken } from "@utils/localStorage";
import { getUserReducer } from "@apps/redux/actions/auth";
import { useAppSelector } from "@apps/hooks";
import { GET_USER } from "@apps/redux/contant";
import { useNavigate } from "react-router-dom";
import { notifyError } from "@utils/helperFuntions";
import { useTranslation } from "react-i18next";

const { Content } = Layout;

interface PageLayoutInterface {
  children: JSX.Element;
  needAuthentication?: boolean;
}

export default function PageLayout({
  children,
  needAuthentication,
}: PageLayoutInterface) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    checkUserAuthenticated();
  }, []);

  const checkUserAuthenticated = async () => {
    const user = await getUser();
    if (needAuthentication && !user) {
      navigate("/");
      notifyError(t("youAreNotLogin"));
    }
  };

  const dispatch = useDispatch();

  const getUser = async () => {
    const accessTokenLocalStorage = readAccessToken();
    if (accessTokenLocalStorage) {
      const data = await getUserReducer(accessTokenLocalStorage);
      dispatch(data);
      if (data.type === GET_USER && data.payload?.user) {
        return data.payload.user;
      }
    }

    return null;
  };

  return (
    <Layout id="app-layout">
      <Header></Header>
      <Content className="site-layout-background">
        <article className="app-content">{children}</article>
      </Content>
      <Footer></Footer>
    </Layout>
  );
}
