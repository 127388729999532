import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import AppProvider from "contexts/AppProvider";
import rootReducer from "@apps/redux/reducers";
// import { store } from "@apps/store";
import i18n from "@locales/i18n";
import App from "./App";
import { GOOGLE_CLIENT_ID } from "@utils/constants";

import "antd/dist/antd.less";
import "@assets/main.scss";

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...[thunk])),
);

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <BrowserRouter>
        <Provider store={store}>
          <I18nextProvider i18n={i18n}>
            <AppProvider>
              <App />
            </AppProvider>
          </I18nextProvider>
        </Provider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
