export const ORDER_ASCEND = "ascend";
export const ORDER_DESCEND = "descend";
export const PATH_API: string =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/api/v1";
export const PATH_API_TEST: string = "http://localhost:8000/api/v1";
export const GOOGLE_CLIENT_ID: string =
  process.env.REACT_APP_GOOGLE_CLIENT_ID || "";
export const CHAT_GPT_API_KEY: string =
  process.env.REACT_APP_CHAT_GPT_API_KEY || "";

export const MENU_ITEMS = {
  HOME: "HOME",
  NEWS: "NEWS",
  ORGANIZATION: "ORGANIZATION",
  TOOLS: "TOOLS",
  STUDY: "STUDY",
  CHAT_SUPPORT: "CHAT_SUPPORT",
};

export const TAB_FILTER_POSTS = {
  LAST_TEST: "newest",
  MOST_VIEW: "most_view",
  EVENT_DU10: "event",
};
