import { lazy } from "react";
import { MENU_ITEMS } from "@utils/constants";

export const routeConfig = [
  {
    id: "HomePage",
    path: "/",
    component: lazy(
      () => import(/* webpackPrefetch: true */ "@pages/home/HomePage"),
    ),
    isProtected: false,
    needAuthentication: false,
    menuActiveItem: MENU_ITEMS.HOME,
  },
  {
    id: "NewsPage",
    path: "/news",
    component: lazy(
      () => import(/* webpackPrefetch: true */ "@pages/news/News"),
    ),
    isProtected: false,
    needAuthentication: false,
    menuActiveItem: MENU_ITEMS.NEWS,
  },
  {
    id: "NewDetailPage",
    path: "/news/:id",
    component: lazy(
      () => import(/* webpackPrefetch: true */ "@pages/news/Detail"),
    ),
    isProtected: false,
    needAuthentication: false,
    menuActiveItem: MENU_ITEMS.NEWS,
  },
  {
    id: "StudyPage",
    path: "/study",
    component: lazy(
      () => import(/* webpackPrefetch: true */ "@pages/study/StudyPage"),
    ),
    isProtected: false,
    needAuthentication: false,
    menuActiveItem: MENU_ITEMS.STUDY,
  },
  {
    id: "ToolPage",
    path: "/tool",
    component: lazy(
      () => import(/* webpackPrefetch: true */ "@pages/tool/AboutUsPage"),
    ),
    isProtected: false,
    needAuthentication: true,
    menuActiveItem: MENU_ITEMS.TOOLS,
  },
  {
    id: "OrganizationPage",
    path: "/organization",
    component: lazy(
      () =>
        import(
          /* webpackPrefetch: true */ "@pages/organization/OrganizationPage"
        ),
    ),
    isProtected: false,
    needAuthentication: true,
    menuActiveItem: MENU_ITEMS.ORGANIZATION,
  },

  {
    id: "ChatSupport",
    path: "/chat-support",
    component: lazy(
      () =>
        import(/* webpackPrefetch: true */ "@pages/chat_support/ChatSupport"),
    ),
    isProtected: false,
    needAuthentication: true,
    menuActiveItem: MENU_ITEMS.CHAT_SUPPORT,
  },
  {
    id: "Newmark",
    path: "/news-watch-later",
    component: lazy(
      () => import(/* webpackPrefetch: true */ "@pages/news/WatchLater"),
    ),
    isProtected: false,
    needAuthentication: true,
    menuActiveItem: MENU_ITEMS.NEWS,
  },
];
