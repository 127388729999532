import { readAccessToken } from "@utils/localStorage";
import axios from "axios";

const accessToken = readAccessToken();

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
  headers: {
    "Content-Type": "application/json",
    environment: process.env.REACT_APP_ENV,
    Authorization: accessToken ? "Bearer " + accessToken : "",
  },
});
