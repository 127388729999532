import React, { Suspense } from "react";
import { useAppDispatch } from "@apps/hooks";
import PageLayout from "./layout/PageLayout";
import { SET_MENU_ITEM_ACTIVE } from "@apps/redux/reducers/home";

function PublicRoute({
  children,
  needAuthentication,
  menuActiveItem,
}: {
  children: JSX.Element;
  needAuthentication: boolean;
  menuActiveItem: string;
}) {
  // const appDispatch = useAppDispatch();
  // appDispatch({ type: SET_MENU_ITEM_ACTIVE, payload: menuActiveItem });

  return (
    <PageLayout needAuthentication={needAuthentication}>
      <Suspense fallback={null}>
        <main className="main-content">{children}</main>
      </Suspense>
    </PageLayout>
  );
}

export default PublicRoute;
