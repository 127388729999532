import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { RootState } from "@apps/redux/reducers";
import { useSelector } from "react-redux";
import { notifyError } from "@utils/helperFuntions";

interface MenuProps {
  items: Array<any>;
  className?: string;
}

export const Menu = ({ items, className }: MenuProps) => {
  const { t } = useTranslation();
  const { logged } = useSelector((state: RootState) => state.auth);
  const checkLogged = () => {
    notifyError(t("youAreNotLogin"));
  };
  const menuItems = useMemo(() => {
    const itemsArr = items.map((menu) => {
      return (
        <li
          key={menu.key}
          className="font-bold text-base text-[#000] mr-[8%] w-[100px]"
        >
          {(!menu.isProtected && !logged) || logged ? (
            <NavLink
              to={menu.url}
              className={({ isActive, isPending }) =>
                isPending
                  ? "nav-item relative cursor-pointer"
                  : isActive
                  ? "active text-[#db2f69] hover:text-[#db2f69] hover:opacity-100"
                  : ""
              }
            >
              <div
                className={`nav-item-label flex items-center justify-center ${
                  !menu.isActive && "hover:opacity-75"
                }`}
              >
                {t(menu.label)}
              </div>
            </NavLink>
          ) : (
            <div
              className={`nav-item-label flex items-center justify-center cursor-pointer ${
                !menu.isActive && "hover:opacity-75"
              }`}
              onClick={() => checkLogged()}
            >
              {t(menu.label)}
            </div>
          )}
        </li>
      );
    });

    return itemsArr;
  }, [items, t, logged]);

  return (
    <ul className={`${className} grid grid-cols-4 lg:grid-cols-6 gap-y-6`}>
      {menuItems}
    </ul>
  );
};
