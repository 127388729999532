import React from "react";
import { NavLink } from "react-router-dom";
import Address from "../../../../assets/Icons/address.svg";
import Phone from "../../../../assets/Icons/phone.svg";
import Website from "../../../../assets/Icons/website.svg";
import Email from "../../../../assets/Icons/email.svg";
import { useTranslation } from "react-i18next";

import "./style.scss";

export default function Footer(): JSX.Element {
  const { t } = useTranslation();
  return (
    <footer className="pt-[75px] pb-4 text-[#9b9b9b] bg-[#f6f6f6] z-10">
      <div className="container">
        <div className="text-center px-3 mb-7 grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* <div className="col-span-1">
            <h5 className="uppercase text-[#454040] font-bold mb-4 text-left pl-20 relative before:content-[''] before:w-3 before:h-1 before:rounded-[5px] before:bg-[#dcdcdc] before:absolute before:top-3 before:left-0">
              Services
            </h5>
            <ul className="text-left mb-4 text-sm">
              <li>
                <NavLink to="/">Contract Development</NavLink>
              </li>
              <li>
                <NavLink to="/">Offshore Development Center (ODC)</NavLink>
              </li>
              <li>
                <NavLink to="/">Blockchain and Web3</NavLink>
              </li>
            </ul>
          </div> */}
          <div className="col-span-1">
            <h5 className="uppercase text-[#454040] font-bold mb-4 text-left pl-20 relative before:content-[''] before:w-3 before:h-1 before:rounded-[5px] before:bg-[#dcdcdc] before:absolute before:top-3 before:left-0">
              {t("contactUs")}
            </h5>
            <ul className="text-left mb-4 text-sm">
              <li className="flex items-center">
                <img
                  src={Address}
                  alt="React Logo"
                  className="mr-3"
                  width={16}
                  height={16}
                />
                <NavLink to="/">
                  Vietnam, Hanoi, Cau Giay Ward, Duy Tan 19, TTC Building, 11th
                  Floor
                </NavLink>
              </li>
              <li className="flex items-center mt-2">
                <img
                  src={Phone}
                  alt="React Logo"
                  className="mr-3"
                  width={16}
                  height={16}
                />
                <NavLink to="/">+84 (24) 3312-0103</NavLink>
              </li>
              <li className="flex items-center mt-2">
                <img
                  src={Website}
                  alt="React Logo"
                  className="mr-3"
                  width={16}
                  height={16}
                />
                <NavLink to="/">https://www.vmogroup.com</NavLink>
              </li>
              <li className="flex items-center mt-2">
                <img
                  src={Email}
                  alt="React Logo"
                  className="mr-3"
                  width={16}
                  height={16}
                />
                <NavLink to="/">info@vmogroup.com</NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-center m-auto px-3 mb-4 text-xs">
          © 2024 DU10 VMOJP. All rights reserved.
        </div>
      </div>
    </footer>
  );
}
