import { IHomeSetting } from "@utils/interface";
import { MENU_ITEMS } from "@utils/constants";

interface IHome {
  homeSetting: IHomeSetting;
  menuActiveItem: string;
}

interface IAction {
  type: string;
  payload: any;
}

const initialState: IHome = {
  homeSetting: {
    darkTheme: false,
    drone: {
      autoMove: true,
    },
    leftLight: {
      turnOn: true,
      intensity: 0.8,
      power: 70,
      color: "#FFFFFF",
    },
    rightLight: {
      turnOn: true,
      intensity: 0.8,
      power: 70,
      color: "#FFFFFF",
    },
    centerLight: {
      turnOn: true,
      intensity: 0.8,
      power: 20,
      color: "#FF0000",
    },
  },
  menuActiveItem: MENU_ITEMS.HOME,
};

export const SET_DARK_THEME = "SET_DARK_THEME";
export const SET_DRONE_AUTO_MOVE = "SET_DRONE_AUTO_MOVE";
export const SET_MENU_ITEM_ACTIVE = "SET_MENU_ITEM_ACTIVE";
export const TOGGLE_LIGHT = "TOGGLE_LIGHT";
export const SET_LIGHT_INTENSITY = "SET_LIGHT_INTENSITY";
export const SET_LIGHT_POWER = "SET_LIGHT_POWER";
export const SET_LIGHT_COLOR = "SET_LIGHT_COLOR";

const homeReducer = (state = initialState, action: IAction) => {
  const { type, payload } = action;
  let lightSetting = null;

  switch (type) {
    case SET_DARK_THEME:
      return {
        ...state,
        homeSetting: {
          ...state.homeSetting,
          darkTheme: payload,
        },
      };
    case SET_DRONE_AUTO_MOVE:
      return {
        ...state,
        homeSetting: {
          ...state.homeSetting,
          drone: {
            ...state.homeSetting.drone,
            autoMove: payload,
          },
        },
      };
    case SET_MENU_ITEM_ACTIVE:
      return {
        ...state,
        menuActiveItem: payload,
      };
    case TOGGLE_LIGHT:
      // @ts-ignore
      lightSetting = state.homeSetting[payload?.lightType];
      if (lightSetting) {
        lightSetting.turnOn = payload.value;
      }
      return {
        ...state,
      };
    case SET_LIGHT_COLOR:
      // @ts-ignore
      lightSetting = state.homeSetting[payload?.lightType];
      if (lightSetting) {
        lightSetting.color = payload.value;
      }
      return {
        ...state,
      };
    case SET_LIGHT_INTENSITY:
      // @ts-ignore
      lightSetting = state.homeSetting[payload?.lightType];
      if (lightSetting) {
        lightSetting.intensity = payload.value;
      }
      return {
        ...state,
      };
    case SET_LIGHT_POWER:
      // @ts-ignore
      lightSetting = state.homeSetting[payload?.lightType];
      if (lightSetting) {
        lightSetting.power = payload.value;
      }
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default homeReducer;
